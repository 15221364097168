<template>
  <div id='operation-box' class="operation-box" v-if="currentTemp">
    <div class="upload-box">
      <div class="upload-head">
        <p class="upload-head-title">添加图片</p>
        <p class="upload-head-desc">最多添加 10 个导航，拖动选中的导航可对其排序</p>
        <p class="upload-head-desc">图标宽高比例建议 1:1</p>
      </div>
      <div class="img-box" v-if='loaded'>
        <a-spin :spinning="imgBoxLoading" v-if="currentTemp.children.length">
          <draggable class="img-draggable" :list="currentTemp.children" group="img-list" >
            <div v-for="(item,index) in currentTemp.children" :key="index" style='margin-bottom: 12px;'>
              <div class="img-item-box">
                <a-icon type="close-circle" class="delete-item" @click="deleteItem(index)" />
                <div class="uploader-card" @click='selectImg($event,index)'>
                  <img v-if="item.imgUrl" :src="baseUrl + item.imgUrl" class="img" />
                  <div v-else>
                    <a-icon type="plus" />
                  </div>
                  <p class="desc" @click='selectImg($event,index)' v-if="item.imgUrl">更换图片</p>
                </div>
                <div class="input-box">
                  <div class="input-content">
                    <p class="input-label">标题：</p>
                    <a-input v-model.trim="item.title" placeholder="建议10字内，可不填"></a-input>
                  </div>
                  <div class="input-content" style="margin-top:8px">
                    <p>链接：</p>
                    <a-button type="link" style="padding: 0" @click="selectLink(index)" v-if="!item.linkType">选择跳转到的页面{{item.linkType}}</a-button>
                    <!-- <a :href="item.linkUrl" target="_blank"  v-if="item.linkType===1" class='link-a'>{{item.linkUrl}}</a> -->
                    <div style='color: #000;' class='link-a' v-if='item.linkType === 2&&item.groupId' :style="{color: getGroupName(item.groupId)?'#000':'red'}">{{getGroupName(item.groupId)||'未找到分组，请重选'}}</div>
                    <div style='color: #000;' class='link-a' v-if='item.linkType === 3'>拼团活动列表</div>
                    <div style='color: #000;' class='link-a' v-if='item.linkType === 4'>砍价活动列表</div>
                    <div style='color: #000;' class='link-a' v-if='item.linkType === 5'>秒杀活动列表</div>
                    <div style='color: #000;' class='link-a' v-if='item.linkType === 8'>直播间列表</div>
                    <div style='color: #000;' class='link-a' v-if='item.linkType === 7&&item.templateId' :style="{color: getTemplateName(item.templateId)?'#000':'red'}">{{getTemplateName(item.templateId)||'未找到模版，请重选'}} </div>
                    <a-button type="link" style="padding: 0" @click="selectLink(index)" v-if="item.linkType">修改</a-button>
                    <a-button type="link" @click="deleteLink(index)" v-if="item.linkType">重置</a-button>
                  </div>
                </div>
              </div>
            </div>

          </draggable>
        </a-spin>

        <div v-if="currentTemp.children.length < 10">
          <a-button icon="plus" style="width: 372px;height:40px;margin: 8px 14px;" type="primary" @click='addItem'>添加图文导航</a-button>
        </div>
      </div>
    </div>
    <div class='img-style-box' v-if="currentTemp.children.length">
      <div class='img-style-item'>
        <div class='label'>每行个数：</div>
        <a-radio-group v-model="currentTemp.rowNum">
          <a-radio-button :value="1">
            1
          </a-radio-button>
          <a-radio-button :value="2">
            2
          </a-radio-button>
          <a-radio-button :value="3">
            3
          </a-radio-button>
          <a-radio-button :value="4">
            4
          </a-radio-button>
          <a-radio-button :value="5">
            5
          </a-radio-button>
        </a-radio-group>
      </div>
      <div class='img-style-item'>
        <div class='label'>模块上下间距：</div>
        <a-slider :default-value="0" :min='0' :max='50' :step='1' v-model="currentTemp.boxPaddingH" tooltipPlacement='right' style='width:180px;margin:0 12px;' />
        <span>{{currentTemp.boxPaddingH}}px</span>
      </div>
      <div class='img-style-item'>
        <div class='label'>模块左右边距：</div>
        <a-slider :default-value="0" :min='0' :max='50' :step='1' v-model="currentTemp.boxPaddingV" tooltipPlacement='right' style='width:180px;margin:0 12px;' />
        <span>{{currentTemp.boxPaddingV}}px</span>
      </div>
      <div class='img-style-item'>
        <div class='label'>图片圆角：</div>
        <a-slider :default-value="0" :min='0' :max='100' :step='1' v-model="currentTemp.radiusNum" tooltipPlacement='right' style='width:180px;margin:0 12px;' />
        <span>{{currentTemp.radiusNum}}%</span>
      </div>
      <div class='img-style-item'>
        <div class='label'>图标大小：</div>
        <a-slider :default-value="0" :min='60' :max='100' :step='1' v-model="currentTemp.size" tooltipPlacement='right' style='width:180px;margin:0 12px;' />
        <span>{{currentTemp.size}}px</span>
      </div>
    </div>
    <a-modal v-model="selectLinkModal" title="选择跳转到的页面" @ok="handleOk">
      <a-spin :spinning='groupLoading'>
        <a-form-model ref="selectForm" :model="linkFrom" :rules="rules" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
          <a-form-model-item label="类型" prop="linkType">
            <a-select v-model="linkFrom.linkType" placeholder="请选择跳转类型">
              <a-select-option :value="2">商品分组</a-select-option>
              <a-select-option :value="7">自定义装修页面</a-select-option>
              <a-select-option :value="3">拼团活动列表</a-select-option>
              <a-select-option :value="4">砍价活动列表</a-select-option>
              <a-select-option :value="5">秒杀活动列表</a-select-option>
              <a-select-option :value="8" v-if='isPlatform'>直播间列表</a-select-option>
            </a-select>
          </a-form-model-item>
          <!-- <a-form-model-item label="链接" prop="linkUrl" v-if="linkFrom.linkType===1">
            <a-input v-model="linkFrom.linkUrl" placeholder='https://' />
            <p>注意：自定义链接域名需在小程序域名管理白名单中</p>
          </a-form-model-item> -->
          <a-form-model-item label="商品分组" prop="groupId" v-if="linkFrom.linkType===2">
            <a-select v-model="linkFrom.groupId" placeholder="请选择">
              <a-select-option :value="item.productGroupId" :key='item.productGroupId' v-for='item in groupList'>{{item.groupName}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="装修页面" prop="templateId" v-if="linkFrom.linkType===7">
            <a-select v-model="linkFrom.templateId" placeholder="请选择">
              <a-select-option :value="item.shopDesignId" :key='item.shopDesignId' v-for='item in templateList'>{{item.title}}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </a-spin>
    </a-modal>
    <select-img-dialog idName='page-box' :show='showSelectImg' @change='handleSelectDone' :initSelectData='initSelectData'></select-img-dialog>
  </div>
</template>
<script>
import { reactive, toRefs, computed, onMounted } from '@vue/composition-api'
import { common, shop } from '@/api'
import draggable from 'vuedraggable'
import { SHOPID } from '@/constants'
import { isPlatform } from '../../../utils/tools'
import { getSession } from '@/utils/session'
export default {
  components: {
    draggable
  },
  props: {
    props: {
      type: Object
    }
  },
  setup (props, { root, emit }) {
    const rules = {
      linkType: [{ required: true, message: '请选择', trigger: 'change' }],
      groupId: [{ required: true, message: '请选择', trigger: 'change' }],
      templateId: [{ required: true, message: '请选择', trigger: 'change' }]
    }
    const baseApi = process.env.VUE_APP_API
    const baseUrl = process.env.VUE_APP_IMG
    const state = reactive({
      groupLoading: false,
      groupList: [],
      selectForm: null,
      linkFrom: {},
      selectLinkModal: false,
      currentIndex: null,
      imgBoxLoading: null,
      showSelectImg: false,
      initSelectData: [],
      currentImgIndex: 0,
      templateList: [],
      loaded: false
    })
    const currentTemp = computed(() => props.props)
    onMounted(async () => {
      await Promise.all([selectGoodsType(), getTemplateList()])
      state.loaded = true
    })
    async function getTemplateList () {
      let { code, data } = await shop.getTemplateList({
        designDataFlag: true
      })
      if (code === '00000') {
        state.templateList = data
      }
    }
    function deleteItem (index) {
      currentTemp.value.children.splice(index, 1)
    }
    function addItem () {
      currentTemp.value.children.push({
        title: '',
        imgUrl: '',
        linkUrl: ''
      })
    }
    function selectLink (index) {
      state.currentIndex = index
      state.linkFrom = deepClone(currentTemp.value.children[state.currentIndex])
      state.selectLinkModal = true
    }
    function deleteLink (index) {
      let obj = currentTemp.value.children[index]
      if (obj.linkType === 1) {
        obj.linkType = null
        obj.linkUrl = ''
      } else if (obj.linkType === 2) {
        obj.linkType = null
        obj.groupId = ''
      } else {
        obj.linkType = null
      }
    }
    function deepClone (obj) {
      let objClone = Array.isArray(obj) ? [] : {}
      if (obj && typeof obj === 'object') {
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            // 判断obj子元素是否为对象，如果是，递归复制
            if (obj[key] && typeof obj[key] === 'object') {
              objClone[key] = deepClone(obj[key])
            } else {
              // 如果不是，简单复制
              objClone[key] = obj[key]
            }
          }
        }
      }
      return objClone
    }
    function handleOk () {
      state.selectForm.validate(async (valid) => {
        if (valid) {
          currentTemp.value.children[state.currentIndex] = state.linkFrom
          state.selectLinkModal = false
        }
      })
    }
    async function selectGoodsType () {
      state.groupLoading = true
      let { code, msg, data } = await common.getProductGroupByShopId({
        shopId: getSession(SHOPID)
      })
      state.groupLoading = false
      if (code === '00000') {
        state.groupList = data
      } else {
        root.$message.error(msg || '获取分组数据失败')
      }
    }
    function handleSelectDone (e) {
      currentTemp.value.children[state.currentImgIndex].imgUrl = e[0]
      state.imgBoxLoading = false
      state.showSelectImg = false
    }
    function selectImg (e, index = undefined) {
      state.showSelectImg = true
      state.currentImgIndex = index
      if (index === undefined) {
        state.initSelectData = currentTemp.value.children.map((x) => x.imgUrl)
      } else {
        state.initSelectData = [currentTemp.value.children[index].imgUrl]
      }
    }
    function getContainer () {
      return document.getElementById('page-box')
    }
    function getGroupName (id) {
      return state.groupList.length && state.groupList.find(val => val.productGroupId === id) ? '商品分组 - ' + state.groupList.find(val => val.productGroupId === id).groupName : null
    }
    function getTemplateName (id) {
      return state.templateList.length && state.templateList.find(val => val.shopDesignId === id) ? state.templateList.find(val => val.shopDesignId === id).title + '-页面' : null
    }
    return {
      isPlatform,
      rules,
      currentTemp,
      baseApi,
      baseUrl,
      ...toRefs(state),
      common,
      deleteItem,
      selectLink,
      handleOk,
      selectGoodsType,
      deleteLink,
      addItem,
      handleSelectDone,
      selectImg,
      getContainer,
      getGroupName,
      getTemplateName
    }
  }
}
</script>
<style lang="less" scoped>
.operation-box {
  background: #fff;
  .operation-type-box {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #666;
    padding: 14px 14px 0;
    .temp-name {
      font-weight: bold;
      color: #000;
      margin-left: 16px;
      font-size: 16px;
    }
  }
  .temp-list {
    padding: 0 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    padding-bottom: 16px;
    border-bottom: 1px solid #ddd;
    .current {
      border: 1px solid #1890ff;
    }
    .temp-item {
      cursor: pointer;
      box-sizing: border-box;
      padding: 8px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: calc(100% / 3);
      font-size: 12px;
      .temp-img {
        width: 88px;
        object-fit: contain;
      }
      .temp-name {
        margin-top: 8px;
      }
    }
  }
  .upload-box {
    padding-top: 24px;
    .upload-head {
      padding: 0 14px;
      .upload-head-title {
        font-size: 18px;
        margin-bottom: 8px;
      }
      .upload-head-desc {
        font-size: 12px;
        color: #666;
        padding-bottom: 8px;
      }
    }
    .img-box {
      background: #eee;
      margin-top: 8px;
      padding: 14px 0;
      .img-item-box {
        position: relative;
        display: flex;
        align-items: center;
        height: 88px;
        margin: 0 12px 0;
        padding: 14px;
        border-radius: 4px;
        background: #fff;
        cursor: pointer;
        &:hover .delete-item {
          display: block;
        }
        .input-box {
          flex: 1;
          .input-content {
            display: flex;
            align-items: center;
            .link-a {
              color: #189ff0;
              margin-right: 12px;
              max-width: 200px;
              line-height: 32px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .input-label {
              flex-shrink: 0;
            }
          }
        }
        .delete-item {
          display: none;
          position: absolute;
          top: -6px;
          right: -8px;
          font-size: 20px;
          background: #fff;
          border-radius: 50%;
          z-index: 1;
          color: #666;
        }
        .uploader-card {
          width: 72px;
          height: 72px;
          position: relative;
          margin-right: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px dashed #ddd;
          border-radius: 4px;
          ::v-deep .img {
            display: block;
            width: 70px;
            height: 70px;
            object-fit: contain;
            position: relative;
          }
          span {
            padding: 0;
            position: relative;
          }
          .desc {
            background: rgba(0, 0, 0, 0.6);
            color: #fff;
            font-size: 12px;
            line-height: 18px;
            position: absolute;
            bottom: 0;
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
  .img-style-box {
    background: #fff;
    padding: 32px 14px 200px;
    .img-style-item {
      display: flex;
      align-items: center;
      margin-bottom: 14px;
      .label {
        width: 8em;
        text-align: right;
      }
      ::v-deep .ant-slider-handle {
        border-color: #189ff0;
      }
      ::v-deep .ant-slider-track {
        background-color: #189ff0;
      }
      ::v-deep .ant-slider-rail {
        background-color: #c1c1c1;
      }
    }
  }
}
.tips {
  padding: 8px 16px;
  color: red;
  font-size: 12px;
}
</style>
